<template>
  <div>
    <v-simple-table
      fixed-header
      :height="tableHeight"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              {{ $t("labels.shift") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.checkin_start") }}
            </th>
            <th role="columnheader" class="text-center primary--text">
              {{ $t("labels.start") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.checkin_end") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.checkout_start") }}
            </th>
            <th role="columnheader" class="text-center error--text">
              {{ $t("labels.end") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.checkout_end") }}
            </th>
            <th role="columnheader" class="text-center warning--text">
              {{ $t("labels.cutoff") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.status") }}
            </th>
            <th role="columnheader" class="text-center" style="width: 100px">
              <v-btn
                color="primary"
                small
                @click="addConfig"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`${item.id}_${index}`"
            class="text-center"
          >
            <td>
              <span v-if="!item.editing">{{ item.name }}</span>
              <v-text-field
                v-else
                v-model="item.name"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.shift')"
                :placeholder="$t('labels.shift')"
                single-line
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.checkin_start }}</span>
              <time-picker-dialog
                v-else
                v-model="item.checkin_start"
                :label="$t('labels.checkin_start')"
                :placeholder="$t('labels.checkin_start')"
              ></time-picker-dialog>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.start }}</span>
              <time-picker-dialog
                v-else
                v-model="item.start"
                :label="$t('labels.start')"
                :placeholder="$t('labels.start')"
              ></time-picker-dialog>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.checkin_end }}</span>
              <time-picker-dialog
                v-else
                v-model="item.checkin_end"
                :label="$t('labels.checkin_end')"
                :placeholder="$t('labels.checkin_end')"
              ></time-picker-dialog>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.checkout_start }}</span>
              <time-picker-dialog
                v-else
                v-model="item.checkout_start"
                :label="$t('labels.checkout_start')"
                :placeholder="$t('labels.checkout_start')"
              ></time-picker-dialog>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.end }}</span>
              <time-picker-dialog
                v-else
                v-model="item.end"
                :label="$t('labels.end')"
                :placeholder="$t('labels.end')"
              ></time-picker-dialog>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.checkout_end }}</span>
              <time-picker-dialog
                v-else
                v-model="item.checkout_end"
                :label="$t('labels.checkout_end')"
                :placeholder="$t('labels.checkout_end')"
              ></time-picker-dialog>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.cutoff }}</span>
              <time-picker-dialog
                v-else
                v-model="item.cutoff"
                :label="$t('labels.cutoff')"
                :placeholder="$t('labels.cutoff')"
              ></time-picker-dialog>
            </td>
            <td>
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  class="mt-0 ml-1"
                  :value="1"
                  :disabled="!item.editing"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <v-btn
                v-if="!item.editing"
                class="mr-1"
                x-small
                color="warning"
                @click="toggleEditing(item, index, true)"
              >
                {{ $t("labels.edit") }}
              </v-btn>
              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="updateConfig(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  class="mr-1"
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "WorkingTimeList",
  components: {},
  props: {},
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    items: [],
    filters: {},
    isLoading: false,
    createDialog: false,
    editItem: {},
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    tableHeight() {
      return "calc(100vh - 205px)";
    },
    statusOptions() {
      return [
        {
          text: "Active",
          color: "success--text",
          value: 1,
        },
        {
          text: "Inactive",
          color: "error--text",
          value: 0,
        },
      ];
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        index: null,
        name: null,
        start: null,
        end: null,
        checkout_start: null,
        checkout_end: null,
        checkin_start: null,
        checkin_end: null,
        status: 1,
        editing: true,
      });
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/working-time-save", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async cancelWorkingTime(item) {
      if (!confirm("Bạn có chắc chắn muốn hủy?")) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/pos-cancel-working-time", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getStatusText(item) {
      return this.statusOptions.find((p) => p.value === item.status) || {};
    },
    getList: debounce(function () {
      httpClient
        .post("/working-time-list", { ...this.filters })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.items = [...data.rows].map((r) => {
            const status = this.getStatusText(r);
            r.status_text = status.text;
            r.status_color = status.color;
            return r;
          });
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped></style>
